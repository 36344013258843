



























































































































































































import Vue from "vue";
import { User } from "@/interfaces/data_objects/user";
import ResetButton from "@/components/recruiter/admin/ResetButton.vue";
import {
  CandidateCardViewOptions,
  ViewCandidatesOptions
} from "@/interfaces/recruiter/admin/all_candidates";
import {
  ActionCandidate,
  ActionCandidatePayload
} from "@/store/modules/recruiter_admin/interfaces";
import { mapActions } from "vuex";
import { ACTION_CANDIDATE } from "@/store/modules/recruiter_admin/constants";
import {
  get_name_initials,
  get_app_assessment_status_color,
  get_app_assessment_status
} from "@/utils/global";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";

export default Vue.extend({
  name: "OnBoardingInvitationCardForCancelled",
  components: { ResetButton },
  data() {
    return {
      dialog: true,
      loading: false
    };
  },
  props: {
    user: {
      type: Object as () => User
    },
    status: {
      type: String as () => AppAssessments.AppAssessmentStatus
    }
  },
  computed: {
    CandidateCardViewOptions() {
      return CandidateCardViewOptions;
    },
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    },
    name_initials() {
      let name = "";
      if (this.user) {
        let first_name = this.user.first_name.trim().split(" ")[0];
        let last_name = this.user.last_name
          ? this.user.last_name.trim().split(" ")[0]
          : "";
        name = first_name + " " + (last_name ? last_name : "");
      }
      return get_name_initials(name);
    },
    is_below_max_width() {
      return this.$vuetify.breakpoint.width < 610;
    }
  },
  methods: {
    get_app_assessment_status_color,
    get_app_assessment_status,
    ...mapActions("recruiter_admin", {
      action_candidate: ACTION_CANDIDATE
    }),
    async btn_action(result: boolean) {
      this.loading = true;
      const payload: ActionCandidatePayload = {
        action: result
          ? ActionCandidate.ACTIVATE_EMAIL
          : ActionCandidate.DEACTIVATE_EMAIL,
        user_ids: [this.user.id]
      };
      await this.action_candidate(payload);
      this.loading = false;
      this.$emit("approve_decline_action");
    }
  }
});
