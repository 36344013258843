




































































































import Vue from "vue";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import AllAssessmentHeader from "@/components/recruiter/admin/AllAssessmentHeader.vue";
import AssessmentCard from "@/components/recruiter/admin/AssessmentCard.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
import {
  SITE_DIRECTION,
  SELECTED_USER_BY_EID_NAME,
  APP_ASSESSMENTS
} from "@/store/modules/common/constants";
import {
  FetchRedoAssessmentCandidatesPayload,
  SearchedCandidate
} from "@/store/modules/recruiter/interfaces";
import {
  GET_COMPANY_DETAILS,
  USER_DETAILS_BY_ID
} from "@/store/modules/auth/constants";
import { User } from "@/interfaces/data_objects/user";
import OnBoardingInvitationCardForCancelled from "@/components/recruiter/OnBoardingInvitationCardForCancelled.vue";
import { FETCH_REDO_ASSESSMENTS_CANDIDATES } from "@/store/modules/recruiter/constants";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";

export default Vue.extend({
  name: "AllCancelledAssessmentCandidates",
  components: {
    DataNotFound,
    AssessmentCard,
    AllAssessmentHeader,
    OnBoardingInvitationCardForCancelled
  },
  data() {
    return {
      loading: true,
      user_object: {} as User,
      assessment_version: "english_v1" as string,
      assessment_id: 1 as null | number,
      on_boarding_invitation: false,
      user_ids: [] as number[],
      assessment_versions: [] as string[],
      reset_btn: false
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION,
      fetch_users: SELECTED_USER_BY_EID_NAME
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS
    }),
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    },
    ...mapGetters("recruiter", {
      get_redo_assessment_candidates: FETCH_REDO_ASSESSMENTS_CANDIDATES
    }),
    ...mapGetters("auth", {
      get_company_details: GET_COMPANY_DETAILS
    })
  },
  mounted() {
    const assessment_setting =
      this.get_company_details.s3_path.assessment_setting;
    this.assessment_version = assessment_setting.candidates_english;
    this.assessment_versions.push(assessment_setting.candidates_english);
    this.assessment_versions.push(assessment_setting.candidates_annotation);
    const assessment = this.app_assessments.find(
      (val: AppAssessments.Assessment) =>
        val.assessment_type === this.assessment_version
    );
    if (!assessment) {
      this.set_root_error(`${this.$t("assessments.not-found")}`);
      this.loading = false;
      return;
    }
    this.assessment_id = assessment.id;
    this.fetch_candidates(this.get_redo_assessment_candidates.page);
  },
  methods: {
    ...mapActions("recruiter", {
      fetch_redo_assessment_candidates: FETCH_REDO_ASSESSMENTS_CANDIDATES
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    ...mapActions("auth", {
      fetch_user_by_id: USER_DETAILS_BY_ID
    }),
    async view_profile(user: SearchedCandidate) {
      if (user) {
        const response = await this.fetch_user_by_id(user.candidate_id);
        this.user_object = response;
        this.on_boarding_invitation = true;
      }
    },
    async fetch_candidates(page = 1, limit = 12) {
      this.loading = true;
      const payload: FetchRedoAssessmentCandidatesPayload = {
        page: page,
        limit: limit,
        assessment_version: this.assessment_version,
        user_ids: this.user_ids
      };
      const response = await this.fetch_redo_assessment_candidates(payload);
      if (!response) {
        this.set_root_error(`${this.$t("shared.failed-to-get")}`);
        // await this.$router.push("/recruiter/dashboard");
        return;
      }
      this.reset_btn =
        this.get_redo_assessment_candidates.results.length > 0 ? true : false;

      this.loading = false;
    },
    get_title(number: number): string {
      if (number === 1) return this.$t("recruiter.no-candidates").toString();
      else return this.$t("shared.no-pending").toString();
    },
    on_close() {
      this.on_boarding_invitation = false;
      this.user_object = {} as User;
    },
    async approve_decline_action() {
      this.on_boarding_invitation = false;
      this.user_object = {} as User;
      await this.fetch_candidates(this.get_redo_assessment_candidates.page);
    },
    async searched_user(ids: number[]) {
      if (ids) {
        this.user_ids = ids;
        await this.fetch_candidates(1);
      }
    },
    async update_assessment_version(assessment_ver: string) {
      if (assessment_ver) {
        const assessment = this.app_assessments.find(
          (val: AppAssessments.Assessment) =>
            val.assessment_type === assessment_ver
        );
        if (!assessment) {
          this.set_root_error(`${this.$t("assessments.not-found")}`);
          this.loading = false;
          return;
        }
        this.assessment_id = assessment.id;
        this.assessment_version = assessment_ver;
        await this.fetch_candidates(1);
      }
    }
  }
});
