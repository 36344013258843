







































































































































































import Vue, { PropType } from "vue";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ACTION_CANDIDATE } from "@/store/modules/recruiter_admin/constants";
import {
  ActionCandidate,
  ActionCandidatePayload
} from "@/store/modules/recruiter_admin/interfaces";
import { TranslateResult } from "vue-i18n";
import AutoCompleteUserAndEidSearch from "@/components/shared/AutoCompleteUserAndEidSearch.vue";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import DeleteItem from "@/components/shared/DeleteItem.vue";

import {
  FETCH_CANCELLED_ASSESSMENTS_CANDIDATES,
  FETCH_REDO_ASSESSMENTS_CANDIDATES,
  RESET_CANCELLED_ASSESSMENT
} from "@/store/modules/recruiter/constants";
import { DeleteCancelledAssessmentPayload } from "@/store/modules/recruiter/interfaces";
import { APP_ASSESSMENTS } from "@/store/modules/common/constants";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { ROOT_ERROR } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "AllAssessmentHeader",
  components: {
    AutoCompleteUserAndEidSearch,
    DeleteItem
  },
  data() {
    return {
      loading: false,
      is_reset_all: false,
      dialog_box: false,
      al_ain_users_items: [
        {
          text: "Yes",
          value: true
        },
        {
          text: "No",
          value: false
        }
      ],
      filter_overlay: false,
      filter_icon: require("@/assets/icons/bold/filter.svg"),
      delete_icon: require("@/assets/icons/bold/reset.svg"),

      filters: [
        { label: this.$t("auth.name"), value: true },
        { label: this.$t("auth.eid-number"), value: false }
      ],
      selected_fitler: this.$t("auth.name") as TranslateResult,
      new_assessment_version: this.assessment_version
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_redo_assessment_candidates: FETCH_REDO_ASSESSMENTS_CANDIDATES
    }),
    ...mapGetters("recruiter", {
      get_cancelled_assessment_candidates:
        FETCH_CANCELLED_ASSESSMENTS_CANDIDATES
    }),
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    },
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS
    })
  },
  props: {
    assessment_versions: {
      type: Array as PropType<string[]>,
      required: true
    },
    assessment_version: {
      type: String as PropType<string>,
      required: true
    },
    page: {
      type: String,
      required: true
    },
    reset_btn: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    ...mapActions("recruiter", {
      reset_cancelled_assessment: RESET_CANCELLED_ASSESSMENT
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    update_assessment_version() {
      this.$emit("update_assessment_version", this.new_assessment_version);
    },

    async dialog_action(value: boolean) {
      this.is_reset_all = value;
      this.dialog_box = false;
      await this.reset_all();
    },
    async reset_submit() {
      this.dialog_box = true;
    },
    async reset_all() {
      const ids: number[] = [];
      if (!this.is_reset_all) {
        for (const candidate of this.get_redo_assessment_candidates.results) {
          ids.push(candidate.user_id);
        }
      }

      const assessment = this.app_assessments.find(
        (val: AppAssessments.Assessment) =>
          val.assessment_type === this.assessment_version
      );
      if (!assessment) {
        this.set_root_error(`${this.$t("assessments.not-found")}`);
        this.loading = false;
        return;
      }
      const assessment_id = assessment.id;
      this.loading = true;
      let is_redo = false;
      console.log("this.page", this.page);
      if (this.page === "redo") {
        is_redo = true;
      }
      const payload: DeleteCancelledAssessmentPayload = {
        assessment_version: assessment_id,
        user_ids: ids,
        is_reset_all: this.is_reset_all,
        is_redo: is_redo
      };
      console.log("payload", payload);
      await this.reset_cancelled_assessment(payload);
      this.loading = false;
      this.get_redo_assessment_candidates.pagination =
        this.get_redo_assessment_candidates.pagination + 1;
      this.$emit("refresh");
    },
    toggle_filter_overlay() {
      this.filter_overlay = !this.filter_overlay;
    },
    filter_change(ind: number, filter: TranslateResult) {
      this.selected_fitler = filter;
      this.filters = this.filters.map((filter, index) => {
        return { ...filter, value: ind === index };
      });
      this.filter_overlay = false;
    },
    searched_user(users: SearchUserObject[]) {
      if (users) {
        this.$emit(
          "searched_user",
          users.map((user) => user.id)
        );
      }
    }
  }
});
