

















import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { CandidateCardViewOptions } from "@/interfaces/recruiter/admin/all_candidates";

export default Vue.extend({
  name: "ApprovedDeclinedButton",
  computed: {
    CandidateCardViewOptions() {
      return CandidateCardViewOptions;
    }
  },
  props: {
    text: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    large_btn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    btn_action() {
      this.$emit("btn_action", true);
    }
  }
});
