
































































import Vue, { PropType } from "vue";
import { IApp } from "@/interfaces";
import {
  CandidateCardViewOptions,
  ViewCandidatesOptions
} from "@/interfaces/recruiter/admin/all_candidates";
import ResetButton from "@/components/recruiter/admin/ResetButton.vue";

import { mapActions } from "vuex";
import { User } from "@/interfaces/data_objects/user";
import { get_name_initials } from "@/utils/global";
import {
  DeleteAssessmentPayload,
  CancelledAssessmentCandidate
} from "@/store/modules/recruiter/interfaces";
import { RESET_ASSESSMENT } from "@/store/modules/recruiter/constants";

export default Vue.extend({
  name: "CandidateCard",
  data() {
    return {
      loading: false,
      user: null as null | User
    };
  },
  computed: {
    IApp() {
      return IApp;
    },
    CandidateCardViewOptions() {
      return CandidateCardViewOptions;
    },
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    }
  },
  components: { ResetButton },
  props: {
    candidate: {
      type: Object as PropType<CancelledAssessmentCandidate>,
      required: true
    },
    on_boarding_btn: {
      type: Boolean,
      default: false
    },
    assessment_id: {
      type: Number,
      required: true
    },
    attempt_number: {
      type: Number,
      required: false,
      default: 0
    }
  },
  methods: {
    get_name_initials,
    ...mapActions("recruiter", {
      reset_assessment: RESET_ASSESSMENT
    }),
    async view_profile() {
      // if (
      //   this.candidate.status === IApp.ResumeUploadsStatus.IN_PROGRESS ||
      //   !this.candidate.upload_response ||
      //   !this.get_name()
      // )
      //   return;
      // sessionStorage.setItem("job", "");
      // sessionStorage.setItem("user", this.candidate.candidate_id.toString());
      // sessionStorage.setItem("upload", this.candidate.upload_response_id);
      // await this.$router.push("/recruiter/candidates/profile");
      this.$emit("view_profile", this.candidate);
    },
    // Function to return experience
    get_experience(): string {
      if (!this.candidate.upload_response) return "";
      const profile = this.candidate.upload_response.profile;
      if (!profile) return "";
      if (!profile.total_experience) return "";
      return profile.total_experience;
    },
    get_name(): string {
      const profile = this.candidate.upload_response.profile;
      if (!profile) return "";
      if (Object.values(profile.personal_details).length === 0) return "";
      return Object.values(profile.personal_details.name).join(" ");
    },
    async btn_action(result: boolean) {
      this.loading = true;
      const payload: DeleteAssessmentPayload = {
        assessment_id: this.assessment_id,
        user_id: this.candidate.id
      };
      await this.reset_assessment(payload);
      this.$emit("action");
      this.loading = false;
    }
  }
});
